import * as React from "react"
import Layout from '../components/layouts/layout'
import SEO from "../components/seo"
import StayInformed from "../components/stayinformed";
import { useMetaDataQuery } from '../hooks/useSiteMetaData'
// import { Link } from "gatsby"


// markup
const NotFoundPage = () => {
  const siteMetadata = useMetaDataQuery();

  const seoData = {
      title: "Rare Disease Patient Services & Treatment Suite | UltraCare",
      description: "UltraCare is dedicated to assisting patients in gaining access to Ultragenyx medicines. Learn more about how we can help you.",
      lang: "en-US"
  }

  return (
    <>
        <Layout slug={"404"}>
            <div className={"container-fluid body-container main 404"}>
              <SEO data={siteMetadata} seoData={seoData} />
              <div className="row">
                <main className="col-sm-12">
                  <section id="section-404" className="hero-section text-md-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>404 Page Not Found</h1>
                                <p>Looks like this page does not exist. <br/>Please check the URL to ensure the path <span className="text-nowrap">is correct.</span></p>
                            </div>
                        </div>
                    </div>
                  </section>
                  <StayInformed data={"404"}/>
                </main>
              </div>
            </div>
        </Layout>
    </>
  )
}

export default NotFoundPage
